body {
    margin: 0;
    font-family: 'NeueHaasDisplay', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

::selection {
    background: #000;
    color: #FFF;
}

a {
    text-decoration: none;
    color: inherit
}

.container {
    margin: auto;
}

.centered-content {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.app-container {
    height: 100vh;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

.top-container, .bottom-container {
    height: 50vh;
}

.headline {
    font-size: 22vw;
    line-height: 50vh;
    font-weight: 900;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    text-transform: uppercase;
}

.headline:last-child {
    color: #FFF;
    position: absolute;
    top: 50%;
}

@media (max-width: 767px) {
    .headline {
        line-height: fill-available !important;
    }
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (max-width: 576px) {
    .container {
        min-width: 100%;
    }

    .app-container, .centered-content {
        height: 100vh;
        max-height: -webkit-fill-available;
    }
}