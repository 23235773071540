.navbar {
    text-align: center;
    text-transform: lowercase;
    font-variant: small-caps;
    font-weight: 600;
    font-size: calc(1.6vw + 8px);
    left: 0;
    right: 0;
    padding: 0 0 5px 0;
    z-index: 1;
    mix-blend-mode: difference;
    color: #FFF;
    display: flex;
    margin: auto;
}

.navlink {
    margin-right: 5vw;
    display: inline-block;
}

.navlink:nth-child(odd) {
    padding-top: 25px;
}

.navlink:nth-child(even) {
    padding-bottom: 25px;
}

.navlink:last-child {
    margin-right: 0 !important;
}

.language {
    position: absolute;
    right: 20px;
    cursor: pointer;
}

@media (max-width: 767px) {
    .navbar {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .navlink {
        padding: 0 !important;
        font-size: 30px;
        margin: 0;
    }

    .navlink:nth-child(odd) {
        text-align: left;
    }

    .navlink:nth-child(even) {
        text-align: right;
    }

    .language {
        top: 10px;
        font-size: 30px;
        line-height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% - 40px);
        left: 20px;
    }

    .language div {
        display: flex;
        flex-direction: row;
    }
}