@font-face {
    font-family: "NeueHaasDisplay";
    src: local("NeueHaasDisplay"), url("./NeueHaasDisplayMediu.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "NeueHaasDisplay";
    src: local("NeueHaasDisplay"), url("./NeueHaasDisplayLight.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "NeueHaasDisplay";
    src: local("NeueHaasDisplay"), url("./NeueHaasDisplayThin.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "NeueHaasDisplay";
    src: local("NeueHaasDisplay"), url("./NeueHaasDisplayBlack.ttf") format("truetype");
    font-weight: 900;
}