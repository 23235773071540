.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 50vh;
    align-items: center;
    padding-top: 30px;
}

.book-img-container {
text-align: center;
}

.book-img {
    height: calc(50vh - 100px)
}