.headline {
    font-size: 22vw;
    line-height: 50vh;
    font-weight: 900;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    text-transform: uppercase;
}

.headline:last-child {
    color: #FFF;
    position: absolute;
    top: 50%;
}

@media (max-width: 767px) {
    .headline {
        line-height: fill-available !important;
    }
}